<template>
  <div class="LanguageSwitcher control">
    <basic-select
      :values="languages"
      :value="value"
      :filterable="false"
      value-prop="value"
      label-prop="label"
      key-prop="value"
      @input="$emit('input', $event)"
    />
  </div>
</template>

<script>
import { Trans } from '@/services/TranslationService'
import BasicSelect from '@hypefactors/shared/js/components/core/BasicSelect.vue'

export default {
  name: 'LanguageSwitcher',
  components: { BasicSelect },

  props: {
    value: {
      type: String,
      default: ''
    }
  },

  computed: {
    languages () {
      return Trans.supportedLanguages.map((lang) => {
        return {
          value: lang,
          label: `${this.$t(`components.language_switcher.languages.${lang}`, 'en')}`
        }
      })
    }
  }
}
</script>
