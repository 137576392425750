<template>
  <page-section class="NewsroomEmbed" :page-title="$t('pages.story_newsroom_embed.page_title')">
    <div>
      <subheading>
        <help-tooltip :content="$t('pages.story_newsroom_embed.section_newsroom_language.sidebar_description')" />
        {{ $t('pages.story_newsroom_embed.section_newsroom_language.title') }}
      </subheading>

      <div class="columns">
        <div class="column is-one-fifth">
          <language-switcher v-model="newsroomLanguage" />
        </div>
      </div>
    </div>

    <v-separator color="grey-dark" />

    <div>
      <subheading>
        <help-tooltip :content="$t('pages.story_newsroom_embed.section_newsroom_elements.sidebar_description')" />
        {{ $t('pages.story_newsroom_embed.section_newsroom_elements.title') }}
      </subheading>

      <div class="columns">
        <div class="column is-one-fifth">
          <ul>
            <li class="is-flex is-aligned-justify is-aligned-middle m-b-s">
              <span>
                {{ $t('pages.story_newsroom_embed.section_newsroom_elements.option_newsroom_heading') }}
              </span>

              <el-checkbox v-model="newsroomSettings.newsroomHeading" />
            </li>

            <li class="is-flex is-aligned-justify is-aligned-middle m-b-s">
              <span>
                {{ $t('pages.story_newsroom_embed.section_newsroom_elements.option_about_section') }}
              </span>

              <el-checkbox v-model="newsroomSettings.aboutSection" />
            </li>

            <li class="is-flex is-aligned-justify is-aligned-middle m-b-s">
              <span>
                {{ $t('pages.story_newsroom_embed.section_newsroom_elements.option_assets_section') }}
              </span>

              <el-checkbox v-model="newsroomSettings.assetsSection" />
            </li>
          </ul>
        </div>
      </div>
    </div>

    <v-separator color="grey-dark" />

    <div>
      <subheading>
        <help-tooltip :content="$t('pages.story_newsroom_embed.section_story_elements.sidebar_description')" />
        {{ $t('pages.story_newsroom_embed.section_story_elements.title') }}
      </subheading>

      <div class="columns">
        <!-- Column 1 -->
        <div class="column is-one-fifth">
          <ul>
            <li class="is-flex is-aligned-justify is-aligned-middle m-b-s">
              <span>
                {{ $t('pages.story_newsroom_embed.section_story_elements.option_story_image') }}
              </span>

              <el-checkbox v-model="storySettings.storyImage" />
            </li>

            <li class="is-flex is-aligned-justify is-aligned-middle m-b-s">
              <span>
                {{ $t('pages.story_newsroom_embed.section_story_elements.option_contact_cards') }}
              </span>

              <el-checkbox v-model="storySettings.contactCards" />
            </li>

            <li class="is-flex is-aligned-justify is-aligned-middle m-b-s">
              <span>
                {{ $t('pages.story_newsroom_embed.section_story_elements.option_attachments') }}
              </span>

              <el-checkbox v-model="storySettings.attachments" />
            </li>

            <li class="is-flex is-aligned-justify is-aligned-middle m-b-s">
              <span>
                {{ $t('pages.story_newsroom_embed.section_story_elements.option_story_type_tag') }}
              </span>

              <el-checkbox v-model="storySettings.storyTypeTag" />
            </li>
          </ul>
        </div>

        <!-- Column 2 -->
        <div class="column is-one-fifth">
          <ul>
            <li class="is-flex is-aligned-justify is-aligned-middle m-b-s">
              <span>
                {{ $t('pages.story_newsroom_embed.section_story_elements.option_date_stamp') }}
              </span>

              <el-checkbox v-model="storySettings.dateStamp" />
            </li>
          </ul>
        </div>
      </div>
    </div>

    <v-separator color="grey-dark" />

    <div>
      <subheading>
        <help-tooltip :content="$t('pages.story_newsroom_embed.section_embed_code.sidebar_description')" />
        {{ $t('pages.story_newsroom_embed.section_embed_code.title') }}
      </subheading>

      <pre class="NewsroomEmbed__script-code">{{ embeddedCode }}</pre>

      <div class="buttons is-right">
        <button
          v-clipboard="embeddedCode"
          type="button"
          class="button is-primary"
          @success="$notify.success($t('success.embed_script_copied'))"
        >
          {{ $t('pages.story_newsroom_embed.section_embed_code.copy_button_label') }}
        </button>
      </div>
    </div>

    <v-separator color="grey-dark" />

    <div>
      <subheading>
        <help-tooltip :content="$t('pages.story_newsroom_embed.section_preview.sidebar_description')" />
        {{ $t('pages.story_newsroom_embed.section_preview.sidebar_title') }}
      </subheading>
      <div v-loading="isLoadingPreview">
        <iframe
          id="embedded-newsroom"
          ref="embeddedNewsroom"
          :src="url"
          style="width: 100%;"
          @load="onPreviewLoaded"
        />
      </div>
    </div>
  </page-section>
</template>

<script>
import { mapGetters } from 'vuex'
import { loadScript } from '@/utils'

import { Trans } from '@/services/TranslationService'
import LanguageSwitcher from '@/components/LanguageSwitcher'
import { getUrlToDomain } from '@hypefactors/shared/js/utils'

export default {
  name: 'NewsroomEmbed',

  components: {
    LanguageSwitcher
  },

  props: {
    newsroomId: {
      type: String,
      default: ''
    }
  },

  data () {
    return {
      isLoading: true,
      isLoadingPreview: true,

      newsroomLanguage: '',

      newsroomSettings: {
        newsroomHeading: true,
        aboutSection: true,
        assetsSection: true
      },

      storySettings: {
        storyImage: true,
        contactCards: true,
        attachments: true,
        storyTypeTag: true,
        dateStamp: true
      }
    }
  },

  computed: {
    ...mapGetters('newsroom', ['findNewsroom']),

    activeNewsroom () {
      return this.findNewsroom(this.newsroomId, 'id')
    },

    url () {
      if (!this.isNewsroomPublished) {
        return ''
      }

      let queryParts = []

      // language
      if (this.newsroomLanguage) {
        queryParts.push(`lang=${this.newsroomLanguage}`)
      }

      // newsroom settings
      const selectedNewsroomSettings = Object.keys(this.newsroomSettings)
        .filter(key => !this.newsroomSettings[key])

      if (selectedNewsroomSettings.length > 0) {
        queryParts.push(`newsroomSettings=${selectedNewsroomSettings.join(',')}`)
      }

      // story settings
      const selectedStorySettings = Object.keys(this.storySettings)
        .filter(key => !this.storySettings[key])

      if (selectedStorySettings.length > 0) {
        queryParts.push(`&storySettings=${selectedStorySettings.join(',')}`)
      }

      let url = getUrlToDomain(`embedded/newsroom/${this.activeNewsroom.slug}/stories`, 'hypenews', false)

      if (queryParts.length > 0) {
        url += `?${queryParts.join('&')}`
      }

      return url
    },

    embeddedCode () {
      return `
        <script>window.iFrameResizer = { targetOrigin: '${process.env.VUE_APP_HYPENEWS_URL}' }${'</' + 'script>'}
        <iframe id="hn-frame" src="${this.url}" style="width: 100%;"></iframe>
        <script src="${this.$getUrl('embed.iife.min.js', 'hypenews', false)}">${'</' + 'script>'}
      `
    },

    isNewsroomPublished () {
      return this.activeNewsroom
        ? this.activeNewsroom.is_published
        : false
    }
  },

  watch: {
    activeBrandId () {
      this.$router.push({ name: 'story.list' })
    },

    url () {
      this.isLoadingPreview = true
    }
  },

  beforeDestroy () {
    this.$refs.embeddedNewsroom.iFrameResizer &&
    this.$refs.embeddedNewsroom.iFrameResizer.close &&
    this.$refs.embeddedNewsroom.iFrameResizer.close()
  },

  mounted () {
    this.newsroomLanguage = Trans.currentLanguage
    this.showNewsroomPublishedWarning()
  },

  methods: {
    onPreviewLoaded (e) {
      window.iFrameResizer = {
        targetOrigin: process.env.VUE_APP_HYPENEWS_URL
      }

      this.isLoadingPreview = false

      function resizeAfterDelay (delay) {
        setTimeout(() => {
          window.iFrameResize({ log: this.$isDevMode, checkOrigin: [process.env.VUE_APP_HYPENEWS_URL] }, '#embedded-newsroom')
        }, delay)
      }

      loadScript('https://unpkg.com/iframe-resizer@3.6.1/js/iframeResizer.min.js', 'iFrameResizer')
        .then(() => {
          resizeAfterDelay.call(this, 3000)
        })
    },

    async showNewsroomPublishedWarning () {
      if (this.isNewsroomPublished) return
      try {
        await this.$confirm(this.$t('pages.story_newsroom_embed.dialog_newsroom_is_not_published.message'), {
          title: this.$t('pages.story_newsroom_embed.dialog_newsroom_is_not_published.title'),
          confirmButtonText: this.$t('forms.publish'),
          cancelButtonText: this.$t('forms.cancel'),
          type: 'warning'
        })

        this.redirectToNewsroomSettings()
      } catch (err) { }
    },

    redirectToNewsroomSettings () {
      if (!this.activeNewsroom) return
      this.$redirectTo(this.$getUrl(`${this.activeNewsroom.slug}/edit`, 'hypenews', false), 0, 'hn_newsroom_edit')
    }
  }
}
</script>

<style lang="scss">
.NewsroomEmbed__script-code {
  font-family: monospace;
  word-break: break-all;
}

.NewsroomEmbed .Banded-section__default-slot {
  padding: 0 2em;
}

.NewsroomEmbed .NewsroomEmbed__preview-container .Banded-section__default-slot {
  padding: 2em 0;
}
</style>
